@font-face {
  font-family: 'BuildingSans';
  src: url('../assets/fonts/BuildingSansLight.otf');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'BuildingSans';
  src: url('../assets/fonts/BuildingSansBold.otf');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../assets/fonts/GraphikBold.otf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../assets/fonts/GraphikBoldItalic.otf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('../assets/fonts/GraphikMedium.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../assets/fonts/GraphikMediumItalic.otf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('../assets/fonts/GraphikRegular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../assets/fonts/GraphikRegularItalic.otf');
  font-weight: normal;
  font-style: italic;
}
